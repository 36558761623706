<template>
  <div>
    <md-card v-if="modelosFiltrados.length > 0">
      <md-card-header class="md-card-header-text md-card-header-blue">
        <div class="card-icon">
          <i class="far fa-star fa-2x"></i>
        </div>
        <h4 class="title">
          Selecione os produtos que serão marcados como favoritos na página
          Consulta de Modelos
        </h4>
      </md-card-header>
      <md-card-content v-if="liberaTela">
        <div class="favoritos">
          <div class="favoritos__valores" v-if="modelosPreferidos">
            <div
              class="favoritos__valores__item"
              :class="{
                'favoritos__valores__item--selecionado':
                  modelosPreferidos.includes(item.Valor),
              }"
              v-for="item in modelosFiltrados"
              :key="item.Valor"
              @click="trocaEstadoFavorito(item.Valor)"
            >
              <span class="box-valores">
                <span class="valor">
                  {{ item.Valor }}
                  <span
                    class="material-icons icone-tipo"
                    v-if="
                      item.TipoPessoaConsulta == 'Física' ||
                      item.TipoPessoaConsulta == 'Indiferente'
                    "
                  >
                    face
                  </span>
                  <span
                    class="material-icons icone-tipo"
                    v-if="
                      item.TipoPessoaConsulta == 'Jurídica' ||
                      item.TipoPessoaConsulta == 'Indiferente'
                    "
                  >
                    business
                  </span>
                </span>
                <span class="descricao">
                  {{ item.Descricao }}
                </span>
              </span>

              <span
                class="material-symbols-outlined icon"
                v-if="!modelosPreferidos.includes(item.Valor)"
              >
                close
              </span>
              <span class="material-symbols-outlined icon" v-else>done</span>
            </div>
          </div>
        </div>
      </md-card-content>
      <md-card-content v-else>
        <Loading />
      </md-card-content>
      <md-card-actions class="text-center">
        <md-button
          native-type="submit"
          @click.native.prevent="salvaFavoritos"
          class="md-primary salvar"
        >
          Salvar
        </md-button>
      </md-card-actions>
    </md-card>
  </div>
</template>

<script>
import { userPreferenceStore } from "@/stores/userPreference";
import { produtosStore } from "@/stores/produtos";
import { mapWritableState, mapActions } from "pinia";
import jsonProdutos from "@/helpers/produtos.json";
import Swal from "sweetalert2";
import Loading from "@/components/Loading";

export default {
  name: "PrefModelosFavoritos",

  components: {
    Loading,
  },

  data() {
    return {
      naoSelecionados: [],
      todosOsProdutos: {},
      liberaTela: false,
    };
  },
  async mounted() {
    await this.getModelos();

    const preferenciasFavoritos = await this.syncPreferenciaGeral(
      "PortalOutbox_modelosFavoritos"
    );

    this.modelosPreferidos = [];

    if (preferenciasFavoritos === undefined) {
      await this.createPreferencia("PortalOutbox_modelosFavoritos");
    } else {
      const valor = preferenciasFavoritos.Valor;
      if (valor != null) {
        this.modelosPreferidos = valor.split(";");
      }
    }

    this.liberaTela = true;
  },
  computed: {
    modelosFiltrados() {
      if (this.modelos) {
        return this.modelos.filter(
          (modelo) => modelo.Descricao !== "-" && modelo.Descricao !== null
        );
      } else {
        return [];
      }
    },
    ...mapWritableState(userPreferenceStore, {
      modelosPreferidos: "modelosPreferidos",
    }),

    ...mapWritableState(produtosStore, {
      modelos: "modelos",
    }),
  },

  watch: {},

  methods: {
    ...mapActions(userPreferenceStore, [
      "createPreferencia",
      "substituiPreferencia",
      "syncPreferenciaGeral",
    ]),

    ...mapActions(produtosStore, ["getModelos"]),

    trocaEstadoFavorito(item) {
      if (this.modelosPreferidos.includes(item)) {
        this.modelosPreferidos = this.modelosPreferidos.filter(
          (produtoPref) => item != produtoPref
        );
      } else {
        this.modelosPreferidos.push(item);
      }
    },

    salvaFavoritos() {
      this.substituiPreferencia(
        "PortalOutbox_modelosFavoritos",
        this.modelosPreferidos.join(";")
      );

      Swal.fire({
        title: "Sucesso",
        text: "Produtos favoritos gravados com sucesso!",
        icon: "success",
        buttonsStyling: false,
        customClass: {
          confirmButton: "md-button md-success",
        },
        confirmButtonText: "Ok",
        showConfirmButton: true,
      });
    },

    alertaSucesso() {
      Swal.fire({
        title: "Sucesso",
        text: "Parâmetros favoritos gravados com sucesso!",
        icon: "success",
        buttonsStyling: false,
        customClass: {
          confirmButton: "md-button md-success",
        },
        confirmButtonText: "Ok",
        showConfirmButton: true,
      });
    },
    alertaErro() {
      Swal.fire({
        title: "Valor inválido",
        text: "Valor inválido ou já existente. Verifique os valores e tente novamente.",
        icon: "warning",
        buttonsStyling: false,
        customClass: {
          confirmButton: "md-button",
        },
        confirmButtonText: "Ok",
        showConfirmButton: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.favoritos {
  margin-top: 2.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__titulo {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 3rem;
  }
  &__valores {
    display: flex;
    gap: 0.3rem;
    flex-wrap: wrap;
    font-size: 0.7rem;
    max-height: 200px;
    overflow-y: scroll;

    @include mediaQuery(medium) {
      width: 100%;
      font-size: 1rem;
      gap: 0.5rem;
      font-weight: 500;
    }

    &__item {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      color: white;
      padding: 5px;
      border-radius: 10px;
      background: $text-gray;
      justify-content: flex-end;

      cursor: pointer;
      transition: 0.3s ease all;
      &--selecionado {
        background: $outbox-medium-blue;
        color: white;
      }

      &:hover {
        box-shadow: 0px 5px 10px 2px white;
      }

      .icon {
        font-size: 0.8rem;
      }
    }
  }
}
.box-valores {
  display: flex;
  flex-direction: column;
}
.valor {
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
}

.descricao {
  font-size: 10px;
  font-weight: 300;
  line-height: 18px;
}

.box-inputs {
  padding: 10px;
  margin: 0 10px;

  .favoritos {
    margin: 5px 0 0 0;
    width: 300px;
  }
}
.salvar {
  height: 30px;
  width: 100px;
}

.icone-tipo {
  margin: 0 0px 0 3px;
  opacity: 0.4;
  font-size: 20px;
}
</style>
