<template>
  <div class="box-inputs">
    <md-card class="box-card">
      <div class="container-left">
        <span class="title-box">
          <h4 class="title-parametros">Novo parâmetro favorito:</h4>
        </span>
        <ValidationObserver
          class="corpo"
          v-slot="{ valid, invalid }"
          ref="observerValidacao"
        >
          <div
            class="favoritos"
            v-for="(campo, indexCampo) in camposGeral"
            :key="indexCampo"
          >
            <ValidationProvider
              :rules="alternaRulesDosCampos(campo)"
              v-slot="{ errors, valid, dirty }"
              :required="campo.Requerido"
              :label="campo.Nome"
            >
              <div class="campo">
                <md-field
                  v-mask="campo.Mascara"
                  :class="selecionaClasseInput(campo, dirty, valid, errors)"
                >
                  <label>{{ campo.Nome }}</label>
                  <md-input
                    class="input-campos"
                    :id="campo.Tipo.replace(/\s+/g, '') + indexCampo"
                    v-model="campo.Valor"
                    :required="campo.Requerido"
                    :type="
                      (ocultarDados && campo.Nome == 'CPF') ||
                      (ocultarDados && campo.Nome == 'CNPJ')
                        ? 'password'
                        : 'text'
                    "
                    :disabled="campo.Disabled"
                    @keyup.enter="
                      valid &&
                      liberaSalvar &&
                      !invalid &&
                      camposGeral[0].Valor.length <= 50
                        ? adicionaParametro()
                        : alertaErro()
                    "
                  />
                </md-field>
                <div class="warning" v-if="campo.Valor.length > 50">
                  Limite max. 50 caracteres
                </div>
              </div>
            </ValidationProvider>
          </div>
          <md-card-actions class="text-center box-button">
            <md-button
              native-type="submit"
              @click.native.prevent="adicionaParametro"
              class="md-primary buttonParametros"
              :disabled="
                !valid ||
                !liberaSalvar ||
                invalid ||
                camposGeral[0].Valor.length > 50
              "
            >
              Salvar
            </md-button>
          </md-card-actions>
        </ValidationObserver>
      </div>
      <div class="container-right">
        <span class="title-box parametros" v-if="imprime.length > 0">
          <h4 class="title-parametros">Parâmetros adicionados:</h4>
        </span>
        <md-card class="title-box parametros vazio" v-else>
          <h4 class="title-parametros vazio">Nenhum parâmetro adicionado.</h4>
        </md-card>

        <div class="box-results" v-if="imprime.length > 0">
          <span
            class="results-parametros"
            v-for="(parametros, index) in imprime"
            :key="index"
          >
            <span class="results-label-box" @click="open(index)">
              <p class="results-label">Descrição:</p>
              <span class="results-tit">
                <p class="text">
                  {{ parametros.Descrição }}
                </p>

                <span
                  class="material-symbols-outlined icon"
                  v-if="openBox[index] == false || openBox[index] == undefined"
                >
                  expand_more
                </span>
                <span
                  class="material-symbols-outlined icon"
                  v-if="openBox[index] == true"
                >
                  expand_less
                </span>
              </span>
            </span>
            <span class="results-box" v-if="openBox[index] == true">
              <span class="left">
                <span
                  class="results-text"
                  v-for="(nome, i) in Object.keys(parametros)"
                  :key="i"
                >
                  <p class="results-text" v-if="i != 0">
                    <strong style="color: white"> {{ nome }}: </strong>
                    {{ formatarCPF(parametros[nome], nome) }}
                  </p>
                </span>
              </span>
              <span class="right">
                <button
                  class="button btn-delete"
                  @click="deletarParametro(parametros, index)"
                >
                  <span class="material-symbols-outlined icon"> delete </span>
                </button>
              </span>
            </span>
          </span>
        </div>
      </div>
    </md-card>
  </div>
</template>

<script>
import { mapActions, mapWritableState } from "pinia";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { userPreferenceStore } from "@/stores/userPreference";

export default {
  name: "PrefParametrosConsulta",

  components: {
    ValidationObserver,
    ValidationProvider,
  },

  data() {
    return {
      liberaInputs: false,
      openBox: new Array(this.imprime.length).fill(false),
      showWarning: false,
    };
  },

  props: {
    liberaTela: Boolean,
    camposGeral: Array,
    imprime: Array,
  },

  computed: {
    liberaSalvar() {
      if (this.camposGeral.length > 2) {
        if (
          this.camposGeral[0].Valor.length > 1 &&
          this.camposGeral[1].Valor.length > 1
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        if (this.camposGeral[0].Valor.length > 1) {
          return true;
        } else {
          return false;
        }
      }
    },

    ...mapWritableState(userPreferenceStore, {
      ocultarDados: "ocultarDados",
    }),
  },

  methods: {
    open(i) {
      this.$set(this.openBox, i, !this.openBox[i]);
    },

    adicionaParametro() {
      this.$emit("adicionaParametro", this.camposGeral);
      this.openBox = Array(this.imprime.length).fill(false);
    },

    formatarCPF(cpf, parametro) {
      if (parametro.toLowerCase() == "cpf") {
        cpf = cpf.replace(/\D/g, "");
        cpf = cpf.replace(/^(\d{3})(\d)/, "$1.$2");
        cpf = cpf.replace(/^(\d{3})\.(\d{3})(\d)/, "$1.$2.$3");
        cpf = cpf.replace(/\.(\d{3})(\d)/, ".$1-$2");

        if (this.ocultarDados) {
          return cpf.replace(
            /(\d{3})\.(\d{3})\.(\d{3})-(\d{2})/,
            "***.$2.$3-**"
          );
        } else {
          return cpf;
        }
      }
      return cpf;
    },

    // adicionarFavorito() {
    //   this.liberaInputs = true;
    // },

    alternaRulesDosCampos(campo) {
      if (campo.Valor.length < 1 && !campo.Requerido) {
        return "";
      } else if (campo.Valor.length > 50) {
        return false;
      } else {
        return campo.Validador;
      }
    },

    selecionaClasseInput(campo, dirty, valid, errors) {
      if (campo.Tamanho > campo.Valor.length) {
        if (dirty && campo.Valor.length != 0) {
          return "md-edit";
        }
      } else if (campo.Tamanho <= campo.Valor.length) {
        if (errors.length > 0) {
          return "md-error";
        }
      }
      if (valid && campo.Valor.length == campo.Tamanho) {
        return "md-valid";
      }
      if (dirty && campo.Valor.length > 50) {
        return "md-edit";
      }
    },

    alertaErro() {
      return;
    },

    deletarParametro(parametro, i) {
      this.openBox[i] = false;
      this.$emit("deletarParametro", parametro, this.camposGeral[0].Tipo);
    },

    ...mapActions(userPreferenceStore, ["syncPreferenciaOcultarDados"]),
  },

  async mounted() {
    this.openBox = Array(this.imprime.length).fill(false);
    this.ocultarDados = await this.syncPreferenciaOcultarDados();
  },
};
</script>

<style lang="scss" scoped>
.favoritos {
  margin-top: 2.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 95%;
  max-width: 465px;
  margin: 5px 0 0 0;

  &__titulo {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 3rem;
  }
  &__valores {
    display: flex;
    gap: 0.3rem;
    flex-wrap: wrap;
    font-size: 0.7rem;

    @include mediaQuery(medium) {
      width: 95%;
      font-size: 1rem;
      gap: 0.5rem;
      font-weight: 500;
    }

    &__item {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      color: white;
      padding: 5px;
      border-radius: 10px;
      background: $text-gray;
      cursor: default;
      transition: 0.3s ease all;
      &--selecionado {
        background: $outbox-medium-blue;
        color: white;
      }

      &:hover {
        box-shadow: 0px 5px 10px 2px white;
      }
    }
  }
}
.box-inputs {
  margin: 0 5px;
  min-height: 100%;
  width: 100%;
  max-height: 500px;
  padding: 0;
  .box-card {
    margin: 0;
    padding: 10px;
    height: 100%;
    display: flex;
    flex-direction: row;
    box-shadow: none !important;

    .container-left {
      width: 50%;
      padding: 0 20px;
    }
    .container-right {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 0 20px;
    }
  }

  .title-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 1px $outbox-medium-blue;
    width: 100%;
    height: 40px;

    &.parametros {
      margin: 0px 0 0 0;
      padding: 0px 0 0 0;
    }
    &.vazio {
      border: none;
      justify-content: center;
      margin: 0px 0 0px 0;
    }
  }
  .title-parametros {
    margin: 15px 0 0 0;
    font-size: 17px;
    font-weight: 300;
    margin: 0;

    &.vazio {
      font-weight: 300;
      font-size: 17px;
      text-align: center;
      width: 100%;
      margin: 0px 0 0 0;
    }
  }
}
.box-results {
  // padding: 10px;
  margin: 10px 0 0 0;
  display: flex;
  align-self: center;
  flex-direction: column;
  overflow-y: scroll;
  width: 100%;
  max-height: 212px;
  height: 212px;

  .results-parametros {
    color: white;
    margin: 5px 5px 0 0;
    width: 95%;
    max-width: 465px;
    gap: 0.1rem;
    color: white;
    border-radius: 10px;
    cursor: default;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .icon {
      cursor: pointer;
      padding: 2.5px;
      font-size: 1rem;
    }
    .results-label-box {
      width: 100%;
      background: $outbox-medium-blue;
      border-radius: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .results-tit {
      padding: 5px;
      margin: 0;
      font-weight: 600;
      display: flex;
      justify-content: space-between;
      flex: 1;
    }
    .text {
      margin: 0;
      white-space: wrap;
      word-break: break-all;

      max-width: 90%;
    }
    .results-label {
      display: flex;
      align-items: center;
      margin: 0 5px;
    }

    .results-box {
      background: $outbox-dark-blue;
      border-radius: 10px;
      padding: 5px;
      width: 100%;
      display: flex;
    }
    .results-text {
      margin: 0;
    }

    .left {
      width: 80%;
    }
    .right {
      width: 20%;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
    }
    .btn-delete {
      display: flex;
      align-items: center;
      font-size: 11px;
      border-radius: 5px;
      height: 25px;
      width: 25px;
      padding: 5px;
      // background: $outbox-medium-blue;
      background: transparent;
      color: white;
      border: none;

      &:hover {
        transform: scale(1.2);
        box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
      }
    }
    .icon {
      font-size: 1.2rem;
      margin: 0;
    }
  }
}
.box-button {
  border: none;
  width: 100%;
  margin: 0;
  padding: 0;
}

.corpo {
  display: flex;
  min-height: 200px;
  flex-direction: column;
  justify-content: space-between;
}

.buttonParametros {
  width: 100px;
  height: 30px;
  min-height: 30px;
  margin: 0 0 0 5px !important;
}
.warning {
  font-size: 12px;
  color: red;
  margin: 0 0 0 0px;
  opacity: 0.7;
  position: absolute;
  line-height: 0px;
}
</style>
