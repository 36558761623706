var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.modelosFiltrados.length > 0)?_c('md-card',[_c('md-card-header',{staticClass:"md-card-header-text md-card-header-blue"},[_c('div',{staticClass:"card-icon"},[_c('i',{staticClass:"far fa-star fa-2x"})]),_c('h4',{staticClass:"title"},[_vm._v(" Selecione os produtos que serão marcados como favoritos na página Consulta de Modelos ")])]),(_vm.liberaTela)?_c('md-card-content',[_c('div',{staticClass:"favoritos"},[(_vm.modelosPreferidos)?_c('div',{staticClass:"favoritos__valores"},_vm._l((_vm.modelosFiltrados),function(item){return _c('div',{key:item.Valor,staticClass:"favoritos__valores__item",class:{
              'favoritos__valores__item--selecionado':
                _vm.modelosPreferidos.includes(item.Valor),
            },on:{"click":function($event){return _vm.trocaEstadoFavorito(item.Valor)}}},[_c('span',{staticClass:"box-valores"},[_c('span',{staticClass:"valor"},[_vm._v(" "+_vm._s(item.Valor)+" "),(
                    item.TipoPessoaConsulta == 'Física' ||
                    item.TipoPessoaConsulta == 'Indiferente'
                  )?_c('span',{staticClass:"material-icons icone-tipo"},[_vm._v(" face ")]):_vm._e(),(
                    item.TipoPessoaConsulta == 'Jurídica' ||
                    item.TipoPessoaConsulta == 'Indiferente'
                  )?_c('span',{staticClass:"material-icons icone-tipo"},[_vm._v(" business ")]):_vm._e()]),_c('span',{staticClass:"descricao"},[_vm._v(" "+_vm._s(item.Descricao)+" ")])]),(!_vm.modelosPreferidos.includes(item.Valor))?_c('span',{staticClass:"material-symbols-outlined icon"},[_vm._v(" close ")]):_c('span',{staticClass:"material-symbols-outlined icon"},[_vm._v("done")])])}),0):_vm._e()])]):_c('md-card-content',[_c('Loading')],1),_c('md-card-actions',{staticClass:"text-center"},[_c('md-button',{staticClass:"md-primary salvar",attrs:{"native-type":"submit"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.salvaFavoritos.apply(null, arguments)}}},[_vm._v(" Salvar ")])],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }