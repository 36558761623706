<template>
  <div>
    <md-card>
      <md-card-header class="md-card-header-text md-card-header-blue">
        <div class="card-icon">
          <i class="far fa-star fa-2x"></i>
        </div>
        <span>
          <h4 class="title">
            Selecione os produtos adicionais que serão pré-selecionados na
            página Consulta de Modelos
          </h4>
        </span>
      </md-card-header>
      <md-card-content class="content" v-if="liberaTela">
        <div class="box-lista" v-if="consultasAdicionaisPF.length > 0">
          <div class="titulo">Pessoa Física</div>
          <div
            v-for="produto in consultasAdicionaisPF"
            :key="produto.Nome"
            class="row"
          >
            <span class="tipo">
              <div class="nome">
                {{ produto.Nome }}
              </div>
              <div class="linha"></div>
              <div class="descricao--shrink">
                {{ produto.Descricao }}
              </div>
              <div class="checkbox">
                <div>
                  <input
                    class="input"
                    type="checkbox"
                    :checked="verificaSelecaoModelos(produto)"
                    @change="selectCard(produto)"
                  />
                </div>
              </div>
            </span>
          </div>
        </div>
        <div class="box-lista" v-if="consultasAdicionaisPJ.length > 0">
          <div class="titulo">Pessoa Jurídica</div>
          <div
            v-for="produto in consultasAdicionaisPJ"
            :key="produto.Nome"
            class="row"
          >
            <span class="tipo">
              <div class="nome">
                {{ produto.Nome }}
              </div>
              <div class="linha"></div>
              <div class="descricao--shrink">
                {{ produto.Descricao }}
              </div>
              <div class="checkbox">
                <div>
                  <input
                    class="input"
                    type="checkbox"
                    :checked="verificaSelecaoModelos(produto)"
                    @change="selectCard(produto)"
                  />
                </div>
              </div>
            </span>
          </div>
        </div>
      </md-card-content>

      <md-card-content v-else>
        <Loading />
      </md-card-content>
      <md-card-actions class="text-center">
        <md-button
          native-type="submit"
          class="md-primary salvar"
          @click="salvaPreferencias()"
        >
          Salvar
        </md-button>
      </md-card-actions>
    </md-card>
  </div>
</template>

<script>
import Loading from "@/components/Loading";
import { userPreferenceStore } from "@/stores/userPreference";
import { mapWritableState, mapActions } from "pinia";
import Swal from "sweetalert2";
import Vue from "vue";

export default {
  name: "PrefModelosAdicionais",

  components: {
    Loading,
  },

  data() {
    return {
      consultasSelecionadas: [],
      liberaTela: false,
    };
  },

  props: {
    // liberaTela: Boolean,
    produtos: Array,
    consultasAdicionais: Array,
  },

  computed: {
    consultasAdicionaisPF() {
      return this.consultasAdicionais.filter((consulta) => {
        return consulta.TipoProduto == "PF";
      });
    },
    consultasAdicionaisPJ() {
      return this.consultasAdicionais.filter((consulta) => {
        return consulta.TipoProduto == "PJ";
      });
    },
    ...mapWritableState(userPreferenceStore, {
      exibirFavoritos: "exibirFavoritos",
    }),
  },
  async mounted() {
    this.verificaPreferencia();
    // this.setupProdutosSuperBox();
  },

  watch: {},

  methods: {
    verificaSelecaoModelos(produto) {
      return this.consultasSelecionadas.some(
        (item) => item.Nome === produto.Nome
      );
    },

    selectCard(produto) {
      const index = this.consultasSelecionadas.findIndex(
        (item) => item.Nome === produto.Nome
      );

      if (index === -1) {
        this.consultasSelecionadas.push(produto);
      } else {
        this.consultasSelecionadas.splice(index, 1);
      }
    },

    async verificaPreferencia() {
      var preferenciaNome = "PortalOutbox_consultaAdicionaisModelos";
      const preferencia = await this.syncPreferenciaGeral(preferenciaNome);

      if (preferencia.Valor == null) {
        var preferenciaString =
          "Dados Cadastrais;Mapa Geral de Riscos;Informações Cadastrais;Detalhamentos Cadastrais da Matriz";
        await this.createPreferencia(preferenciaNome);
        await this.substituiPreferencia(preferenciaNome, preferenciaString);

        this.consultasAdicionais.forEach((produto) => {
          if (produto.Nome == preferenciaNome) {
            produtosEncontrados.push(produto);
          }
        });
      } else {
        const valorGet = preferencia.Valor;
        const objetos = valorGet.split(";");

        const produtosEncontrados = [];

        this.consultasAdicionais.forEach((produto) => {
          if (objetos.includes(produto.Nome)) {
            produtosEncontrados.push(produto);
          }
        });

        if (produtosEncontrados.length > 0) {
          this.consultasSelecionadas.push(...produtosEncontrados);
        }
      }

      this.liberaTela = true;
    },

    salvaPreferencias() {
      const preferenciaNome = "PortalOutbox_consultaAdicionaisModelos";
      const preferenciasString = this.consultasSelecionadas
        .map((produto) => {
          return `${produto.Nome}`;
        })
        .join(";");

      this.substituiPreferencia(preferenciaNome, preferenciasString);
      Swal.fire({
        title: "Sucesso",
        text: "Produtos favoritos gravados com sucesso!",
        icon: "success",
        buttonsStyling: false,
        customClass: {
          confirmButton: "md-button md-success",
        },
        confirmButtonText: "Ok",
        showConfirmButton: true,
      });
    },

    ...mapActions(userPreferenceStore, [
      "syncPreferenciaGeral",
      "createPreferencia",
      "substituiPreferencia",
    ]),
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.salvar {
  height: 30px;
  width: 100px;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
  cursor: default;
}

.row {
  width: 100%;
  display: flex;
}
.tipo {
  padding: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  // border-bottom: 1px solid $outbox-medium-blue;
  border-bottom: 1px solid #d8d8d8;
  margin: 0 10px;
  color: #86878f;
}

.box-lista {
  padding: 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.titulo {
  font-size: 17px;
  font-weight: 400;
}

.nome {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 400;
  width: 170px;
  word-break: break-word;
  cursor: default;
  line-height: 18px;
}

.linha {
  width: 1px;
  height: 30px;
  background-color: #d8d8d8;
}
.descricao--shrink {
  font-weight: 300;
  font-size: 14px;
  width: 60%;
  margin: 0 20px;
}

.checkbox {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

input[type="checkbox"] {
  appearance: none;
  background-color: white;
  margin: 0;
  font: inherit;
  width: 18px;
  height: 18px;
  border: 0.15em solid #1da0f2;
  border-radius: 5px;
  transform: translateY(-0.075em);
  cursor: pointer;
  display: grid;
  place-content: center;
}

input:checked {
  background-color: #1da0f2;
}

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em white;

  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}
</style>
