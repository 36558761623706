<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-text md-card-header-blue">
          <div class="card-icon">
            <i class="far fa-edit fa-2x"></i>
          </div>
          <h4 class="title">Altere os seus dados de usuário aqui:</h4>
          &nbsp;&nbsp;<md-progress-spinner
            :md-diameter="15"
            :md-stroke="1"
            md-mode="indeterminate"
            v-show="carregando"
          ></md-progress-spinner>
          <small v-if="mensagemErroCarregamento">{{
            mensagemErroCarregamento
          }}</small>
        </md-card-header>
        <ValidationObserver
          v-slot="{ invalid, touched }"
          ref="observerValidacaoDadosPrincipais"
        >
          <md-card-content>
            <div class="md-layout md-alignment-center-center">
              <div class="md-layout-item md-size-100">
                <div class="md-layout md-alignment-center-center">
                  <div class="md-layout-item md-size-66 md-small-size-100">
                    <div class="md-layout-item md-size-100">
                      <ValidationProvider
                        class="md-layout"
                        :rules="validacoes.nomeAmigavel"
                        v-slot="{ errors }"
                      >
                        <md-field
                          :class="[
                            { 'md-valid': errors.length == 0 && touched },
                            { 'md-error': errors.length > 0 },
                          ]"
                        >
                          <md-icon>face</md-icon>

                          <label>Nome amigável</label>
                          <md-input
                            v-model="nomeAmigavel"
                            data-vv-name="nomeAmigavel"
                            type="text"
                            required
                          >
                          </md-input>
                          <slide-y-down-transition>
                            <md-icon class="error" v-show="errors.length > 0"
                              >close</md-icon
                            >
                          </slide-y-down-transition>
                          <slide-y-down-transition>
                            <md-icon
                              class="success"
                              v-show="errors.length == 0 && touched"
                              >done</md-icon
                            >
                          </slide-y-down-transition>
                        </md-field>
                      </ValidationProvider>
                    </div>
                    <div class="md-layout-item md-size-100">
                      <ValidationProvider
                        class="md-layout"
                        :rules="validacoes.email"
                        v-slot="{ errors }"
                      >
                        <md-field
                          :class="[
                            { 'md-valid': errors.length == 0 && touched },
                            { 'md-error': errors.length > 0 },
                          ]"
                        >
                          <md-icon>mail_outline</md-icon>

                          <label>E-mail</label>
                          <md-input
                            v-model="email"
                            data-vv-name="email"
                            type="text"
                            required
                          >
                          </md-input>
                          <slide-y-down-transition>
                            <md-icon class="error" v-show="errors.length > 0"
                              >close</md-icon
                            >
                          </slide-y-down-transition>
                          <slide-y-down-transition>
                            <md-icon
                              class="success"
                              v-show="errors.length == 0 && touched"
                              >done</md-icon
                            >
                          </slide-y-down-transition>
                        </md-field>
                      </ValidationProvider>
                    </div>

                    <div class="md-layout-item md-size-100">
                      <md-field>
                        <md-icon>verified_user</md-icon>
                        <label>Nome de usuário</label>
                        <md-input
                          name="username"
                          v-model="username"
                          disabled
                          type="text"
                        >
                        </md-input>
                      </md-field>
                    </div>
                  </div>

                  <div
                    class="md-layout-item md-size-33 md-small-size-100 conteudo-horizontal-centralizado"
                  >
                    <div class="img-circle">
                      <template v-if="!base64Foto">
                        <div class="image-container">
                          <img :src="base64Foto" title="" />
                        </div>
                      </template>
                      <div class="image-container" v-else>
                        <img :src="base64Foto" />
                      </div>
                      <div class="button-container">
                        <md-button
                          class="md-warning"
                          @click="removeImagem('circle')"
                          v-if="base64Foto"
                          ><i class="fa fa-times"></i>Remover</md-button
                        >
                        <md-button class="md-success md-fileinput">
                          <template v-if="!base64Foto">Adicionar</template>
                          <template v-else>Trocar</template>
                          <input
                            type="file"
                            name="circle"
                            @change="imagemAlterada"
                          />
                        </md-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </md-card-content>
          <md-card-actions class="text-center">
            <md-button
              :disabled="
                invalid || salvandoDadosPrincipais || dadosPrincipaisInalterados
              "
              native-type="submit"
              @click.native.prevent="salvaDadosPrincipais"
              class="md-primary"
              >Salvar</md-button
            >
            <md-progress-spinner
              :md-diameter="15"
              :md-stroke="1"
              md-mode="indeterminate"
              v-show="salvandoDadosPrincipais"
            ></md-progress-spinner>
          </md-card-actions>
        </ValidationObserver>
      </md-card>

      <md-card>
        <md-card-header class="md-card-header-text md-card-header-blue">
          <div class="card-icon">
            <i class="fas fa-asterisk fa-2x"></i>
          </div>
          <h4 class="title">Altere sua senha aqui:</h4>
        </md-card-header>
        <ValidationObserver
          v-slot="{ invalid, touched }"
          ref="observerValidacaoSenha"
        >
          <md-card-content>
            <div class="md-layout md-alignment-center-center">
              <div class="md-layout-item md-size-33 md-small-size-100">
                <ValidationProvider
                  class="md-layout"
                  :rules="validacoes.senhaNova"
                  v-slot="{ errors }"
                  vid="password"
                >
                  <md-field
                    :class="[
                      {
                        'md-valid':
                          errors.length == 0 && touched && !showPassWarning,
                      },
                      { 'md-error': errors.length > 0 || showPassWarning },
                    ]"
                  >
                    <md-icon>lock</md-icon>
                    <label>Nova senha (de 6 a 20 caracteres)</label>
                    <md-input
                      autocomplete="off"
                      v-on:input="checkNovaSenha"
                      name="senhaNova"
                      v-model="senhaNova"
                      type="password"
                      ref="password"
                    >
                    </md-input>
                    <slide-y-down-transition>
                      <md-icon class="error" v-show="errors.length > 0"
                        >close</md-icon
                      >
                    </slide-y-down-transition>
                    <slide-y-down-transition>
                      <md-icon
                        class="success"
                        v-show="errors.length == 0 && touched"
                        >done</md-icon
                      >
                    </slide-y-down-transition>
                  </md-field>
                </ValidationProvider>
              </div>
              <div class="md-layout-item md-size-33 md-small-size-100">
                <ValidationProvider
                  class="md-layout"
                  :rules="validacoes.senhaNovaConfirmacao"
                  v-slot="{ errors }"
                >
                  <md-field
                    :class="[
                      {
                        'md-valid':
                          errors.length == 0 && touched && !showPassWarning,
                      },
                      { 'md-error': errors.length > 0 || showPassWarning },
                    ]"
                  >
                    <md-icon>lock</md-icon>
                    <label>Confirmação da nova senha</label>
                    <md-input
                      autocomplete="off"
                      v-model="senhaNovaConfirmacao"
                      name="senhaNovaConfirmacao"
                      type="password"
                    >
                    </md-input>
                    <slide-y-down-transition>
                      <md-icon class="error" v-show="errors.length > 0"
                        >close</md-icon
                      >
                    </slide-y-down-transition>
                    <slide-y-down-transition>
                      <md-icon
                        class="success"
                        v-show="errors.length == 0 && touched"
                        >done</md-icon
                      >
                    </slide-y-down-transition>
                  </md-field>
                </ValidationProvider>
              </div>
              <div class="md-layout-item md-size-33 md-small-size-100">
                <ValidationProvider
                  class="md-layout"
                  :rules="validacoes.senhaAtual"
                  v-slot="{ errors }"
                >
                  <md-field
                    :class="[
                      { 'md-valid': errors.length == 0 && touched },
                      { 'md-error': errors.length > 0 },
                    ]"
                  >
                    <md-icon>lock_open</md-icon>
                    <label>Senha atual</label>
                    <md-input
                      autocomplete="off"
                      v-model="senhaAtual"
                      type="password"
                    >
                    </md-input>
                    <slide-y-down-transition>
                      <md-icon class="error" v-show="errors.length > 0"
                        >close</md-icon
                      >
                    </slide-y-down-transition>
                    <slide-y-down-transition>
                      <md-icon
                        class="success"
                        v-show="errors.length == 0 && touched"
                        >done</md-icon
                      >
                    </slide-y-down-transition>
                  </md-field>
                </ValidationProvider>
              </div>
            </div>
            <div class="passWarning" v-if="showPassWarning">
              Caractere inválido (":")
            </div>
          </md-card-content>
          <md-card-actions class="text-center">
            <md-button
              :disabled="invalid || salvandoSenha || showPassWarning"
              native-type="submit"
              @click.native.prevent="salvaSenha"
              class="md-primary"
              >Salvar</md-button
            >
            <md-progress-spinner
              :md-diameter="15"
              :md-stroke="1"
              md-mode="indeterminate"
              v-show="salvandoSenha"
            ></md-progress-spinner>
          </md-card-actions>
        </ValidationObserver>
      </md-card>
      <md-card>
        <md-card-header class="md-card-header-text md-card-header-blue">
          <div class="card-icon">
            <i class="far fa-star fa-2x"></i>
          </div>
          <h4 class="title">Dados adicionais:</h4>
          &nbsp;&nbsp;<md-progress-spinner
            :md-diameter="15"
            :md-stroke="1"
            md-mode="indeterminate"
            v-show="carregando"
          ></md-progress-spinner>
          <small v-if="mensagemErroCarregamento">{{
            mensagemErroCarregamento
          }}</small>
        </md-card-header>
        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item md-size-25 md-small-size-100">
              <strong>Empresa:</strong>&nbsp; {{ empresa }}
            </div>
            <div class="md-layout-item md-size-75 md-small-size-100">
              <strong>Membro dos grupos de acesso:</strong>&nbsp;
              <span v-for="(grupo, index) in grupos" :key="grupo.Nome">
                <span class="grupoBox">{{ grupo }}</span
                ><span v-if="index + 1 < grupos.length">, </span>
              </span>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import { userService } from "@/services";
import { chatOutboxStore } from "@/stores/chat-Outbox.js";
import { mapWritableState, mapActions } from "pinia";
import { constantes } from "@/helpers";
import { SlideYDownTransition } from "vue2-transitions";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Swal from "sweetalert2";

export default {
  components: {
    SlideYDownTransition,
    ValidationObserver,
    ValidationProvider,
  },

  props: {},

  data() {
    return {
      salvandoNome: false,
      salvandoEmail: false,
      salvandoFoto: false,
      salvandoSenha: false,
      carregando: true,

      mensagemErroSalvandoNome: "",
      mensagemErroSalvandoEmail: "",
      mensagemErroSalvandoFoto: "",
      mensagemErroSalvandoSenha: "",
      mensagemErroCarregamento: "",

      username: "",
      nomeAmigavel: "",
      email: "",
      base64Foto: "",

      nomeAmigavelGravado: "",
      emailGravado: "",
      base64FotoGravado: "",

      empresa: "",
      grupos: [],

      senhaAtual: "",
      senhaNova: "",
      senhaNovaConfirmacao: "",

      validacoes: {
        nomeAmigavel: {
          required: true,
        },
        email: {
          required: true,
          email: true,
        },

        senhaAtual: {
          required: true,
        },
        senhaNova: {
          required: true,
          min: 6,
          max: 20,
        },
        senhaNovaConfirmacao: {
          required: true,
          confirmed: "password",
        },
      },

      showPassWarning: false,
    };
  },

  methods: {
    verificaSeTerminouSalvamentoDadosPrincipaisEDisparaAlerta() {
      if (!this.salvandoDadosPrincipais) {
        var sucesso =
          this.mensagemErroSalvandoNome == "" &&
          this.mensagemErroSalvandoEmail == "" &&
          this.mensagemErroSalvandoFoto == "";

        if (sucesso) {
          Swal.fire({
            title: "Sucesso",
            text: "Dados gravados com sucesso!",
            icon: "success",
            buttonsStyling: false,
            customClass: {
              confirmButton: "md-button md-success",
            },
            confirmButtonText: "Ok",
            showConfirmButton: true,
          }).then((result) => {
            var modeloEvento = {
              nomeAmigavel: this.nomeAmigavel,
              base64Foto: this.base64Foto,
              email: this.email,
            };
            this.$root.$emit("atualizouDadosUsuario", modeloEvento);
          });
        } else {
          Swal.fire({
            title: "Erro",
            text: "Ocorreram um ou mais erros na gravação, tente novamente mais tarde.",
            icon: "error",
            buttonsStyling: false,
            customClass: {
              confirmButton: "md-button md-warning",
            },
            confirmButtonText: "Ok",
            showConfirmButton: true,
          });
        }
      }
    },

    disparaAlertaTrocaSenhaTerminada() {
      if (this.mensagemErroSalvandoSenha) {
        Swal.fire({
          title: "Erro",
          text: this.mensagemErroSalvandoSenha,
          type: "error",
          buttonsStyling: false,
          customClass: {
            confirmButton: "md-button md-warning",
          },
          confirmButtonText: "Ok",
          showConfirmButton: true,
        });
      } else {
        Swal.fire({
          title: "Sucesso",
          text: "Senha alterada com sucesso!",
          type: "success",
          buttonsStyling: false,
          customClass: {
            confirmButton: "md-button md-success",
          },
          confirmButtonText: "Ok",
          showConfirmButton: true,
        });
      }
    },

    salvaDadosPrincipais() {
      this.$refs.observerValidacaoDadosPrincipais.validate().then((isValid) => {
        if (isValid) {
          this.mensagemErroSalvandoNome = "";
          this.mensagemErroSalvandoEmail = "";
          this.mensagemErroSalvandoFoto = "";

          this.salvandoNome = this.nomeAmigavel != this.nomeAmigavelGravado;
          this.salvandoEmail = this.email != this.emailGravado;
          this.salvandoFoto = this.base64Foto != this.base64FotoGravado;

          if (this.salvandoNome) {
            userService
              .atualizaNomeAmigavelUsuarioLogado(this.nomeAmigavel)
              .then(
                (resultado) => {
                  this.salvandoNome = false;
                  this.nomeAmigavelGravado = this.nomeAmigavel;
                  this.verificaSeTerminouSalvamentoDadosPrincipaisEDisparaAlerta();
                },
                (erro) => {
                  this.mensagemErroSalvandoNome =
                    "Erro ao salvar o 'nome amigável'.";
                  this.salvandoNome = false;
                  this.verificaSeTerminouSalvamentoDadosPrincipaisEDisparaAlerta();
                }
              );
          }

          if (this.salvandoEmail) {
            userService.atualizaEmailUsuarioLogado(this.email).then(
              (resultado) => {
                this.salvandoEmail = false;
                this.emailGravado = this.email;
                this.verificaSeTerminouSalvamentoDadosPrincipaisEDisparaAlerta();
              },
              (erro) => {
                this.mensagemErroSalvandoEmail = "Erro ao salvar o 'E-mail'.";
                this.salvandoEmail = false;
                this.verificaSeTerminouSalvamentoDadosPrincipaisEDisparaAlerta();
              }
            );
          }

          if (this.salvandoFoto) {
            userService.atualizaFotoUsuarioLogado(this.base64Foto).then(
              (resultado) => {
                this.salvandoFoto = false;
                this.base64FotoGravado = this.base64Foto;
                this.verificaSeTerminouSalvamentoDadosPrincipaisEDisparaAlerta();
              },
              (erro) => {
                this.mensagemErroSalvandoFoto = "Erro ao salvar a 'Foto'.";
                this.salvandoFoto = false;
                this.verificaSeTerminouSalvamentoDadosPrincipaisEDisparaAlerta();
              }
            );
          }
        }
      });
    },

    checkNovaSenha() {
      this.senhaNovaConfirmacao = "";

      if (this.senhaNova.includes(":")) {
        this.showPassWarning = true;
      } else {
        this.showPassWarning = false;
      }
    },

    salvaSenha() {
      this.$refs.observerValidacaoSenha.validate().then((isValid) => {
        if (isValid) {
          this.salvandoSenha = true;
          this.mensagemErroSalvandoSenha = "";

          userService
            .alteraSenhaUsuarioLogado(this.senhaAtual, this.senhaNova)
            .then(
              (resultado) => {
                this.disparaAlertaTrocaSenhaTerminada();
                this.salvandoSenha = false;
              },
              (erro) => {
                this.mensagemErroSalvandoSenha = erro;
                this.disparaAlertaTrocaSenhaTerminada();
                this.salvandoSenha = false;
              }
            );
        }
      });
    },

    imagemAlterada(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      this.criaImagem(files[0]);
    },

    criaImagem(file) {
      let reader = new FileReader();
      let vm = this;

      reader.onload = (e) => {
        vm.base64Foto = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    removeImagem: function () {
      this.base64Foto = constantes.getImagemAvatarPadrao();
    },

    recuperaDadosUsuario: function () {
      this.base64Foto = constantes.getImagemAvatarPadrao();

      userService.recuperaDadosUsuarioLogado().then(
        (usuario) => {
          this.username = usuario.Usuario;
          this.nomeAmigavel = usuario.NomeAmigavel;
          this.nomeAmigavelGravado = this.nomeAmigavel;

          this.email = usuario.Email;
          this.emailGravado = this.email;

          this.base64Foto = usuario.Foto;
          this.base64FotoGravado = this.base64Foto;

          this.empresa = usuario.Empresa;
          this.grupos = usuario.Grupos;

          if (!this.base64Foto)
            this.base64Foto = constantes.getImagemAvatarPadrao();

          this.carregando = false;

          var modeloEvento = {
            nomeAmigavel: this.nomeAmigavel,
            base64Foto: this.base64Foto,
          };
          this.$root.$emit("atualizouDadosUsuario", modeloEvento);
        },
        (error) => {
          this.carregando = false;
          this.mensagemErroCarregamento =
            "Erro ao carregar dados do usuário logado.";
        }
      );
    },

    ...mapActions(chatOutboxStore, ["recebeContexto"]),

  },

  mounted() {
    const contexto = {
      codigo_agrupador: "",
      rota: this.$route.path,
      nome: this.$route.name,
      descricao: "",
    };

    this.recebeContexto(contexto);
    
    this.recuperaDadosUsuario();
  },

  computed: {
    salvandoDadosPrincipais: function () {
      return this.salvandoNome || this.salvandoEmail || this.salvandoFoto;
    },

    dadosPrincipaisInalterados: function () {
      return (
        this.nomeAmigavel == this.nomeAmigavelGravado &&
        this.email == this.emailGravado &&
        this.base64Foto == this.base64FotoGravado
      );
    },
  },
  watch: {},

  beforeDestroy() {},
};
</script>

<style scoped>
.passWarning {
  font-size: 12px;
  color: red;
  margin-left: 15px;
  opacity: 0.7;
}

.grupoBox {
  background-color: #91d3e94b;
  border-radius: 20px;
  padding: 1px 10px;
  white-space: nowrap;
}
</style>
