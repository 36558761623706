<template>
  <div class="content-fixed">
    <transition name="grow-width">
      <div class="texto-box" v-if="abreMenu">
        <transition name="fade-in">
          <span v-if="conteudoVisivel" class="box-transition">
            <span v-if="!exibirTooltips">Exibir tooltips</span>
            <span v-if="exibirTooltips">Ocultar tooltips</span>
            <md-switch
              v-model="exibirTooltips"
              @change="alteraPrefTooltip()"
              class="switch"
            >
            </md-switch>
          </span>
        </transition>
      </div>
    </transition>

    <span class="icon-box">
      <span
        v-if="exibirTooltips"
        class="material-symbols-outlined icon-visib"
        @click="alteraMenu()"
      >
        visibility
      </span>
      <span
        v-if="!exibirTooltips"
        class="material-symbols-outlined icon-visib"
        @click="alteraMenu()"
      >
        visibility_off
      </span>
    </span>
  </div>
</template>

<script>
// import Loading from "@/components/Loading";
import { userPreferenceStore } from "@/stores/userPreference";
import { mapWritableState, mapActions } from "pinia";
import Swal from "sweetalert2";
import Vue from "vue";

export default {
  name: "PrefTooltipTela",

  components: {
    // Loading,
  },

  data() {
    return {
      exibirTooltips: null,
      abreMenu: false,
      conteudoVisivel: false,
    };
  },

  props: {},

  watch: {
    abreMenu(novoValor) {
      if (novoValor) {
        this.conteudoVisivel = true;
        setTimeout(() => {}, 500);
      } else {
        this.conteudoVisivel = false;
      }
    },
  },

  computed: {
    ...mapWritableState(userPreferenceStore, {
      exibirTootipsConsultas: "exibirTootipsConsultas",
    }),
  },
  async mounted() {
    await this.syncPreferenciaConsultasTooltips();
    this.exibirTooltips = this.exibirTootipsConsultas;
  },

  methods: {
    alteraMenu() {
      if (!this.abreMenu) {
        setTimeout(() => {
          this.conteudoVisivel = true;
        }, 500);
      } else {
        this.conteudoVisivel = false;
      }
      this.abreMenu = !this.abreMenu;

      if (this.abreMenu) {
        setTimeout(() => {
          this.conteudoVisivel = false;
          this.abreMenu = false;
        }, 5000);
      }
    },

    async alteraPrefTooltip() {
      const preferenciaNome = "PortalOutbox_consultasTooltips";

      await this.substituiPreferencia(preferenciaNome, this.exibirTooltips);
      await this.syncPreferenciaConsultasTooltips();
      this.exibirTooltips = this.exibirTootipsConsultas;

      setTimeout(() => {
        this.abreMenu = false;
      }, 3000);

      this.$emit("recebePrefTooltips", this.exibirTooltips);
    },

    salvaPreferencias() {
      const preferenciaNome = "PortalOutbox_consultasTooltips";
      // const preferenciasString = this.produtosSuperBoxData
      //   .map((produto) => {
      //     return `${produto.Box}=${produto.selecionado}`;
      //   })
      //   .join(";");

      // this.substituiPreferencia(preferenciaNome, preferenciasString);

      // Swal.fire({
      //   title: "Sucesso",
      //   text: "Produtos favoritos gravados com sucesso!",
      //   icon: "success",
      //   buttonsStyling: false,
      //   customClass: {
      //     confirmButton: "md-button md-success",
      //   },
      //   confirmButtonText: "Ok",
      //   showConfirmButton: true,
      // });
    },

    ...mapActions(userPreferenceStore, [
      "syncPreferenciaGeral",
      "createPreferencia",
      "substituiPreferencia",
      "syncPreferenciaConsultasTooltips",
    ]),
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.content-fixed {
  position: fixed;
  right: 0px;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  z-index: 999;
  margin: 15px;
  gap: 10px;
  height: 30px;
  transition: 0.3s;
}
.texto-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  font-size: 14px;
  font-weight: 300;
  text-align: center;
  width: 180px;
  line-height: 14px;
  background-color: #d6d6d6;
  border-radius: 20px;
  padding: 8px 15px;
  transition: width 0.5s ease-out, opacity 0.5s ease-out; /* Transição para width e opacity */
  overflow: hidden;
  height: 36px;
}

.box-transition {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
}

.switch {
  margin: 0;
}

.icon-box {
  padding: 5px;
  font-size: 24px;
  border-radius: 50%;
  background-color: white;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.icon-visib {
  cursor: pointer;
  // color: #4356a5;
  color: #0389dd;
  transition: 0.3s;

  &:hover {
    transform: scale(1.1);
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
  cursor: default;
}

.fade-in-enter-active,
.fade-in-leave-active {
  transition: opacity 0.5s ease-in;
}

.fade-in-enter,
.fade-in-leave-to {
  opacity: 0;
}

.grow-width-enter-active,
.grow-width-leave-active {
  transition: width 0.5s ease-out, opacity 0.5s ease-out;
}

.grow-width-enter,
.grow-width-leave-to {
  width: 0; /* Começa com largura 0 e cresce até o tamanho total */
  opacity: 0; /* Começa invisível */
}
</style>
