<template>
  <div>
    <md-card>
      <md-card-header class="md-card-header-text md-card-header-blue">
        <div class="card-icon">
          <i class="far fa-star fa-2x"></i>
        </div>
        <span>
          <h4 class="title">Preferências Gerais</h4>
        </span>
      </md-card-header>

      <md-card-content class="content top" v-if="liberaTela">
        <div class="texto-box">
          <div class="sub-titulo">Ocultar dados pessoais</div>
          <span class="texto">
            Deseja ocultar informações de
            <strong> documentos </strong>
            exibidos na
            <strong>página inicial </strong>

            e nas consultas de
            <strong> Modelo </strong>
            e
            <strong> Superbox. </strong>
          </span>
        </div>

        <div class="box-container">
          <md-switch v-model="ocultarDados" class="sw"> </md-switch>
        </div>
      </md-card-content>
      <md-card-content class="content" v-if="liberaTela">
        <div class="texto-box">
          <div class="sub-titulo">Formato do arquivo de download</div>
          <span class="texto">
            Selecione o
            <strong> formato </strong>
            do arquivo ao realizar o download dos resultados de consultas de
            <strong> Modelo </strong>
            e
            <strong> Superbox. </strong>
          </span>
        </div>

        <div class="box-container">
          <div
            v-for="(tipo, index) in listaTiposDownload"
            :key="index"
            class="body-box"
            :class="{ selecionado: tipoDownloadConsulta == tipo }"
            @click="selecionaTipo(tipo)"
          >
            <span class="nome">{{ tipo }}</span>
            <span
              class="material-symbols-outlined icon"
              v-if="tipoDownloadConsulta != tipo"
            >
              close
            </span>
            <span class="material-symbols-outlined icon" v-else>done</span>
          </div>
        </div>
      </md-card-content>
      <md-card-content class="content" v-if="liberaTela && liberaChat">
        <div class="texto-box">
          <div class="sub-titulo">Termos de Uso Assistente Outbox</div>
          <span class="texto">
            Usuário concorda com os
            <a :href="linkTermos" target="_blank">
              <strong> Termos de Uso </strong>
            </a>
            do nosso serviço de chat, incluindo o uso de seus dados para
            melhorar a experiência de atendimento e a conformidade com nossas
            políticas de privacidade.
          </span>
        </div>

        <div class="box-container">
          <md-switch v-model="termosUsoAssistente" class="sw"> </md-switch>
        </div>
      </md-card-content>
      <md-card-content v-else>
        <Loading />
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import Loading from "@/components/Loading";
import { userPreferenceStore } from "@/stores/userPreference";
import { chatOutboxStore } from "@/stores/chat-Outbox.js";
import { mapWritableState, mapActions } from "pinia";
import Swal from "sweetalert2";
import Vue from "vue";

export default {
  name: "PrefGerais",

  components: {
    Loading,
  },

  data() {
    return {
      listaTiposDownload: ["pdf", "zip"],
      abreDetalhes: [],
      linkTermos: "/docs/Termos de serviço - Assistente Outbox.pdf",
    };
  },

  props: {
    liberaTela: Boolean,
    produtos: Array,
    produtosSuperBoxCust: Array,
  },

  watch: {
    ocultarDados: async function (newVal, oldVal) {
      if (newVal != oldVal) {
        await this.switchOcultarDados();
      }
    },
    tipoDownloadConsulta: async function (newVal, oldVal) {
      if (newVal != oldVal) {
        await this.switchTipoDownloadConsulta();
      }
    },
    termosUsoAssistente: async function (newVal, oldVal) {
      if (newVal != oldVal) {
        await this.switchTermosUsoAssistente();
      }
      if (!newVal) {
        this.chatAberto = false;
      }
    },
  },

  computed: {
    listaProdutosSuperBoxCust() {
      return this.produtosSuperBoxCust.map((item) => item.Valor);
    },

    produtosSuperBox() {
      return this.produtosSuperBoxData;
    },

    liberaChat() {
      if (
        this.usuarioLogado.Grupos.includes("Acesso ao Portal Outbox - Chat") ||
        this.usuarioLogado.Empresa == "StepWise"
      ) {
        return true;
      }

      return false;
    },

    ...mapWritableState(userPreferenceStore, {
      usuarioLogado: "usuarioLogado",
      ocultarDados: "ocultarDados",
      tipoDownloadConsulta: "tipoDownloadConsulta",
      termosUsoAssistente: "termosUsoAssistente",
    }),

    ...mapWritableState(chatOutboxStore, {
      chatAberto: "chatAberto",
    }),
  },

  async mounted() {
    this.ocultarDados = await this.syncPreferenciaOcultarDados();
    this.tipoDownloadConsulta = await this.syncPreferenciaTipoDownload();

    const nomePrefTermosDeUso = "PortalOutbox_TermosDeUsoAssistente";
    const prefTermosUsoAssistente = await this.syncPreferenciaGeral(
      nomePrefTermosDeUso
    );
    this.termosUsoAssistente = prefTermosUsoAssistente.Valor == "true";
  },

  methods: {
    selecionaTipo(tipo) {
      this.tipoDownloadConsulta = tipo;
    },

    async switchOcultarDados() {
      try {
        await this.substituiPreferencia(
          "PortalOutbox_OcultarDados",
          this.ocultarDados.toString()
        );
      } catch (err) {}
    },

    async switchTipoDownloadConsulta() {
      try {
        await this.substituiPreferencia(
          "PortalOutbox_TipoDownload",
          this.tipoDownloadConsulta
        );
      } catch (err) {}
    },
    async switchTermosUsoAssistente() {
      try {
        await this.substituiPreferencia(
          "PortalOutbox_TermosDeUsoAssistente",
          this.termosUsoAssistente
        );
      } catch (err) {}
    },

    ...mapActions(userPreferenceStore, [
      "syncPreferenciaTipoDownload",
      "createPreferencia",
      "substituiPreferencia",
      "syncPreferenciaOcultarDados",
      "syncPreferenciaGeral",
    ]),
  },
};
</script>

<style lang="scss" scoped>
.content {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin: 0px 25px 10px 25px;
  border-bottom: 1px solid #eeeeee;

  &.top {
    margin: 10px 25px 0px 25px;
    border-top: 1px solid #eeeeee;
  }
}

.box-container {
  height: 100%;
  display: flex;
  gap: 10px;
}

.body-box {
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  background: $text-gray;
  color: white;
  border-radius: 10px;
  height: 35px;
  cursor: pointer;

  &.selecionado {
    background: #1da0f2;
  }
}

.texto-box {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.sub-titulo {
  font-weight: 400;
  font-size: 17px;
}

.texto {
  font-size: 15px;
  font-weight: 300;
  line-height: 18px;
}

.nome {
  height: 35px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  line-height: 18px;
  text-transform: uppercase;
  // gap: 5px;
}
.icon {
  font-size: 14px;
}

.opcoes {
  display: flex;
  gap: 10px;
  flex-direction: column;
  width: 100%;
  padding: 10px 5px 10px 0;
}
.seleciona {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $bg-sidebar;
  padding: 5px;
  border-radius: 10px;
  background: $text-gray;
  cursor: pointer;
  transition: 0.3s ease all;
  &.selecionado {
    background: white;
    color: $outbox-medium-blue;
  }
}
.submit-box {
  border: none;
}

.salvar {
  height: 30px;
  width: 100px;
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
  cursor: default;
}
</style>
