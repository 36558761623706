<template>
  <div>
    <md-card>
      <md-card-header class="md-card-header-text md-card-header-blue">
        <div class="card-icon">
          <i class="far fa-star fa-2x"></i>
        </div>
        <span>
          <h4 class="title">
            Adicione parâmetros que serão sugeridos nas páginas de Consultas
          </h4>
          <md-switch v-model="exibirFavoritos" class="sw">
            Exibir Parâmetros Favoritos na tela de Consultas.
          </md-switch>
          <!-- <md-switch v-model="ocultarDados" class="sw">
            Ocultar dados sensíveis
          </md-switch> -->
        </span>
      </md-card-header>
      <div>
        <div class="tab-buttons-box">
          <button
            class="tab-buttons"
            @click="selecionaAba('pf')"
            :class="{ selecionado: abaSelecionada === 'pf' }"
          >
            Pessoa Física
          </button>
          <button
            class="tab-buttons"
            @click="selecionaAba('pj')"
            :class="{ selecionado: abaSelecionada === 'pj' }"
          >
            Pessoa Jurídica
          </button>
          <button
            class="tab-buttons"
            @click="selecionaAba('geo')"
            :class="{ selecionado: abaSelecionada === 'geo' }"
          >
            Geolocalização
          </button>
        </div>
        <md-card-content v-if="liberaTela" class="card-inputs">
          <PrefParametrosInput
            v-if="abaSelecionada === 'pf'"
            :camposGeral="camposPF"
            :imprime="imprimePf"
            :ocultarDados="ocultarDados"
            @adicionaParametro="adicionaParametro"
            @deletarParametro="deletarParametro"
          />
          <PrefParametrosInput
            v-if="abaSelecionada === 'pj'"
            :camposGeral="camposPJ"
            :imprime="imprimePj"
            :ocultarDados="ocultarDados"
            @adicionaParametro="adicionaParametro"
            @deletarParametro="deletarParametro"
          />
          <PrefParametrosInput
            v-if="abaSelecionada === 'geo'"
            :camposGeral="camposGEO"
            :imprime="imprimeGeo"
            :ocultarDados="ocultarDados"
            @adicionaParametro="adicionaParametro"
            @deletarParametro="deletarParametro"
          />
        </md-card-content>
        <md-card-content v-else>
          <Loading />
        </md-card-content>
      </div>
    </md-card>
  </div>
</template>

<script>
import Loading from "@/components/Loading";
import PrefParametrosInput from "@/pages/MinhaConta/PrefParametrosInput.vue";
import { userPreferenceStore } from "@/stores/userPreference";
import { mapWritableState, mapActions } from "pinia";

export default {
  name: "PrefParametrosConsulta",

  components: {
    Loading,
    PrefParametrosInput,
  },

  data() {
    return {
      abaSelecionada: "pf",
    };
  },

  props: {
    liberaTela: Boolean,
    camposPF: Array,
    camposPJ: Array,
    camposGEO: Array,
    imprimePf: Array,
    imprimePj: Array,
    imprimeGeo: Array,
  },

  computed: {
    ...mapWritableState(userPreferenceStore, {
      exibirFavoritos: "exibirFavoritos",
      ocultarDados: "ocultarDados",
    }),
  },
  async mounted() {
    this.exibirFavoritos = await this.syncPreferenciaExibeParametros();
  },
  methods: {
    adicionaParametro(campoInput) {
      this.$emit("adicionaParametro", campoInput, campoInput[0].Tipo);
    },

    deletarParametro(parametro, tipo) {
      this.$emit("deletarParametro", parametro, tipo);
    },

    selecionaAba(aba) {
      this.abaSelecionada = aba;
    },

    async switchExibirFavoritos() {
      try {
        await this.substituiPreferencia(
          "PortalOutbox_ExibeParametros",
          this.exibirFavoritos.toString()
        );
      } catch (err) {}
    },
    
    ...mapActions(userPreferenceStore, [
      "substituiPreferencia",
      "syncPreferenciaExibeParametros",
    ]),
  },
  watch: {
    exibirFavoritos: async function (newVal, oldVal) {
      if (newVal != oldVal) {
        await this.switchExibirFavoritos();
      }
    },

  },
};
</script>

<style lang="scss" scoped>
.card-inputs {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.tab-buttons-box {
  // width: 100%;
  margin: 20px 25px 0px 25px;
  font-size: 16px;
  display: flex;
}

.tab-buttons {
  border: none;
  text-transform: uppercase;
  margin: 0 5px;
  border-radius: 5px;
  background: #eeeeee;
  color: $outbox-medium-blue;
  padding: 0 10px;
  // width: 160px;
  height: 40px;
  font-weight: 500;
  flex: 1;

  &.selecionado {
    background: $outbox-medium-blue;
    color: white;
  }
}

.sw {
  margin: 20px 0 0 5px;
}
</style>
<style>
.md-switch.md-checked .md-switch-container {
  background-color: #4356a5 !important;
}
</style>
