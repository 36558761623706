<template>
  <div>
    <md-card>
      <md-card-header class="md-card-header-text md-card-header-blue">
        <div class="card-icon">
          <i class="far fa-star fa-2x"></i>
        </div>
        <span>
          <h4 class="title">Preferências de consultas customizadas na página Consulta Superbox</h4>
        </span>
      </md-card-header>
      <!-- v-if="produto.listaCustomizadas != undefined" -->
      <md-card-content class="content" v-if="liberaTela">
        <div
          v-for="(produto, index) in produtosSuperBoxData"
          :key="produto.Box"
        >
          <div class="body-box">
            <span class="nome">
              {{ produto.Nome }}
              <span
                :id="'expand' + index"
                class="material-symbols-outlined icon"
                v-if="!abreDetalhes[index]"
                @click="open(index)"
                :class="{ disabled: produto.listaCustomizadas == undefined }"
              >
                expand_more
              </span>
              <span
                class="material-symbols-outlined icon"
                v-else
                @click="open(index)"
              >
                expand_less
              </span>
              <!-- v-if="produto.listaCustomizadas == undefined" -->
            </span>
            <!-- <b-tooltip
              :target="'expand' + index"
              triggers="hover"
              placement="bottom"
              variant="dark"
              class="tooltipTexts"
              boundary="window"
              >
              Produtos customizados disponíveis
            </b-tooltip> -->
            <div class="opcoes" v-if="abreDetalhes[index]">
              <span
                class="seleciona codigo"
                :class="{ selecionado: produto.selecionado == produto.Box }"
                @click="alteraSelecionado(produto.Box)"
              >
                {{ produto.Box }}
              </span>
              <span
                class="seleciona custom"
                v-for="customizada in produto.listaCustomizadas"
                :key="customizada"
                :class="{ selecionado: produto.selecionado == customizada }"
                @click="alteraSelecionado(customizada)"
              >
                {{ customizada }}
              </span>
            </div>
          </div>
        </div>
      </md-card-content>

      <md-card-content v-else>
        <Loading />
      </md-card-content>
      <md-card-actions class="text-center">
        <md-button
          native-type="submit"
          class="md-primary salvar"
          @click="salvaPreferencias()"
        >
          Salvar
        </md-button>
      </md-card-actions>
    </md-card>
  </div>
</template>

<script>
import Loading from "@/components/Loading";
import { userPreferenceStore } from "@/stores/userPreference";
import { mapWritableState, mapActions } from "pinia";
import Swal from "sweetalert2";
import Vue from "vue";

export default {
  name: "PrefSuperbox",

  components: {
    Loading,
  },

  data() {
    return {
      produtosSuperBoxData: [],
      abreDetalhes: [],
    };
  },

  props: {
    liberaTela: Boolean,
    produtos: Array,
    produtosSuperBoxCust: Array,
  },

  computed: {
    listaProdutosSuperBoxCust() {
      return this.produtosSuperBoxCust.map((item) => item.Valor);
    },

    produtosSuperBox() {
      return this.produtosSuperBoxData;
    },

    ...mapWritableState(userPreferenceStore, {
      exibirFavoritos: "exibirFavoritos",
    }),
  },
  async mounted() {
    this.setupProdutosSuperBox();
  },

  methods: {
    open(index) {
      Vue.set(this.abreDetalhes, index, !this.abreDetalhes[index]);
    },

    alteraSelecionado(produtoSelect) {
      const boxTratado = produtoSelect.split("_")[0];

      const index = this.produtosSuperBoxData.findIndex(
        (produto) => produto.Box === boxTratado
      );

      if (index !== -1) {
        this.$set(this.produtosSuperBoxData, index, {
          ...this.produtosSuperBoxData[index],
          selecionado: produtoSelect,
        });
      }
    },

    setupProdutosSuperBox() {
      const prodSuperBox = this.produtos.filter(
        (produto) =>
          !produto.Box.includes("_") && produto.Box.startsWith("BOXSUPPF")
      );

      const valoresCustomizados = this.produtos.filter(
        (produto) =>
          produto.Box.includes("_") && produto.Box.startsWith("BOXSUPPF")
      );

      this.produtosSuperBoxData = prodSuperBox.map((produto) => {
        valoresCustomizados.forEach((valor) => {
          if (valor.Box.startsWith(produto.Box)) {
            produto.listaCustomizadas =
              valoresCustomizados.length > 0 ? [valor.Box] : [];
          }
        });
        this.abreDetalhes = Array(this.produtosSuperBoxData.length).fill(false);

        produto.selecionado = produto.Box;

        return produto;
      });

      this.verificaPreferencia();
    },

    async verificaPreferencia() {
      const preferenciaNome = "PortalOutbox_consultaSuperBox";
      const preferencia = await this.syncPreferenciaGeral(preferenciaNome);

      if (preferencia.Valor == null) {
        await this.createPreferencia(preferenciaNome);
      } else {
        const valorGet = preferencia.Valor;
        const objetos = valorGet.split(";").map((item) => {
          const valores = item.split("=");

          const index = this.produtosSuperBoxData.findIndex(
            (produto) => produto.Box === valores[0]
          );

          if (index !== -1) {
            this.$set(this.produtosSuperBoxData, index, {
              ...this.produtosSuperBoxData[index],
              selecionado: valores[1],
            });
          }
        });
      }
    },

    salvaPreferencias() {
      const preferenciaNome = "PortalOutbox_consultaSuperBox";
      const preferenciasString = this.produtosSuperBoxData
        .map((produto) => {
          return `${produto.Box}=${produto.selecionado}`;
        })
        .join(";");

      this.substituiPreferencia(preferenciaNome, preferenciasString);

      Swal.fire({
        title: "Sucesso",
        text: "Produtos favoritos gravados com sucesso!",
        icon: "success",
        buttonsStyling: false,
        customClass: {
          confirmButton: "md-button md-success",
        },
        confirmButtonText: "Ok",
        showConfirmButton: true,
      });
    },

    ...mapActions(userPreferenceStore, [
      "syncPreferenciaGeral",
      "createPreferencia",
      "substituiPreferencia",
    ]),
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  // flex-direction: column;
}

.body-box {
  padding: 5px 10px;
  display: flex;
  // gap: 10px;
  align-items: flex-start;
  justify-content: center;
  width: 250px;
  flex-direction: column;
  background: #1da0f2;
  color: white;
  border-radius: 10px; 
  min-height: 35px;
}

.nome {
  height: 25px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  line-height: 16px;
  // gap: 5px;
}
.opcoes {
  display: flex;
  gap: 10px;
  flex-direction: column;
  width: 100%;
  padding: 10px 5px 10px 0;
}
.seleciona {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $bg-sidebar;
  padding: 5px;
  border-radius: 10px;
  background: $text-gray;
  cursor: pointer;
  transition: 0.3s ease all;
  &.selecionado {
    background: white;
    color: $outbox-medium-blue;
  }
}

.salvar {
  height: 30px;
  width: 100px;
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
  cursor: default;
}
</style>
