var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box-inputs"},[_c('md-card',{staticClass:"box-card"},[_c('div',{staticClass:"container-left"},[_c('span',{staticClass:"title-box"},[_c('h4',{staticClass:"title-parametros"},[_vm._v("Novo parâmetro favorito:")])]),_c('ValidationObserver',{ref:"observerValidacao",staticClass:"corpo",scopedSlots:_vm._u([{key:"default",fn:function({ valid, invalid }){return [_vm._l((_vm.camposGeral),function(campo,indexCampo){return _c('div',{key:indexCampo,staticClass:"favoritos"},[_c('ValidationProvider',{attrs:{"rules":_vm.alternaRulesDosCampos(campo),"required":campo.Requerido,"label":campo.Nome},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid, dirty }){return [_c('div',{staticClass:"campo"},[_c('md-field',{directives:[{name:"mask",rawName:"v-mask",value:(campo.Mascara),expression:"campo.Mascara"}],class:_vm.selecionaClasseInput(campo, dirty, valid, errors)},[_c('label',[_vm._v(_vm._s(campo.Nome))]),_c('md-input',{staticClass:"input-campos",attrs:{"id":campo.Tipo.replace(/\s+/g, '') + indexCampo,"required":campo.Requerido,"type":(_vm.ocultarDados && campo.Nome == 'CPF') ||
                    (_vm.ocultarDados && campo.Nome == 'CNPJ')
                      ? 'password'
                      : 'text',"disabled":campo.Disabled},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;valid &&
                    _vm.liberaSalvar &&
                    !invalid &&
                    _vm.camposGeral[0].Valor.length <= 50
                      ? _vm.adicionaParametro()
                      : _vm.alertaErro()}},model:{value:(campo.Valor),callback:function ($$v) {_vm.$set(campo, "Valor", $$v)},expression:"campo.Valor"}})],1),(campo.Valor.length > 50)?_c('div',{staticClass:"warning"},[_vm._v(" Limite max. 50 caracteres ")]):_vm._e()],1)]}}],null,true)})],1)}),_c('md-card-actions',{staticClass:"text-center box-button"},[_c('md-button',{staticClass:"md-primary buttonParametros",attrs:{"native-type":"submit","disabled":!valid ||
              !_vm.liberaSalvar ||
              invalid ||
              _vm.camposGeral[0].Valor.length > 50},nativeOn:{"click":function($event){$event.preventDefault();return _vm.adicionaParametro.apply(null, arguments)}}},[_vm._v(" Salvar ")])],1)]}}])})],1),_c('div',{staticClass:"container-right"},[(_vm.imprime.length > 0)?_c('span',{staticClass:"title-box parametros"},[_c('h4',{staticClass:"title-parametros"},[_vm._v("Parâmetros adicionados:")])]):_c('md-card',{staticClass:"title-box parametros vazio"},[_c('h4',{staticClass:"title-parametros vazio"},[_vm._v("Nenhum parâmetro adicionado.")])]),(_vm.imprime.length > 0)?_c('div',{staticClass:"box-results"},_vm._l((_vm.imprime),function(parametros,index){return _c('span',{key:index,staticClass:"results-parametros"},[_c('span',{staticClass:"results-label-box",on:{"click":function($event){return _vm.open(index)}}},[_c('p',{staticClass:"results-label"},[_vm._v("Descrição:")]),_c('span',{staticClass:"results-tit"},[_c('p',{staticClass:"text"},[_vm._v(" "+_vm._s(parametros.Descrição)+" ")]),(_vm.openBox[index] == false || _vm.openBox[index] == undefined)?_c('span',{staticClass:"material-symbols-outlined icon"},[_vm._v(" expand_more ")]):_vm._e(),(_vm.openBox[index] == true)?_c('span',{staticClass:"material-symbols-outlined icon"},[_vm._v(" expand_less ")]):_vm._e()])]),(_vm.openBox[index] == true)?_c('span',{staticClass:"results-box"},[_c('span',{staticClass:"left"},_vm._l((Object.keys(parametros)),function(nome,i){return _c('span',{key:i,staticClass:"results-text"},[(i != 0)?_c('p',{staticClass:"results-text"},[_c('strong',{staticStyle:{"color":"white"}},[_vm._v(" "+_vm._s(nome)+": ")]),_vm._v(" "+_vm._s(_vm.formatarCPF(parametros[nome], nome))+" ")]):_vm._e()])}),0),_c('span',{staticClass:"right"},[_c('button',{staticClass:"button btn-delete",on:{"click":function($event){return _vm.deletarParametro(parametros, index)}}},[_c('span',{staticClass:"material-symbols-outlined icon"},[_vm._v(" delete ")])])])]):_vm._e()])}),0):_vm._e()],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }