<template>
  <div>
    <PrefGerais
      :liberaTela="liberaTela"
      :produtos="produtos"
      :produtosSuperBoxCust="produtosSuperBoxCust"
    />
    <PrefParametrosConsulta
      :liberaTela="liberaTela"
      :imprimePf="imprimePf"
      :imprimePj="imprimePj"
      :imprimeGeo="imprimeGeo"
      :camposPF="camposPF"
      :camposPJ="camposPJ"
      :camposGEO="camposGEO"
      @adicionaParametro="adicionaParametro"
      @deletarParametro="alertaDeletarPergunta"
    />
    <md-card>
      <md-card-header class="md-card-header-text md-card-header-blue">
        <div class="card-icon">
          <i class="far fa-star fa-2x"></i>
        </div>
        <h4 class="title">
          Selecione os produtos que serão marcados como favoritos na página
          Consulta de Box
        </h4>
      </md-card-header>
      <md-card-content v-if="liberaTela">
        <div class="favoritos">
          <div class="favoritos__valores" v-if="produtosPreferidos">
            <div
              class="favoritos__valores__item"
              :class="{
                'favoritos__valores__item--selecionado':
                  produtosPreferidos.includes(item),
              }"
              v-for="item in Object.keys(todosOsProdutos)"
              :key="item"
              @click="trocaEstadoFavorito(item)"
            >
              {{ todosOsProdutos[item] }}
              <span
                class="material-symbols-outlined icon"
                v-if="!produtosPreferidos.includes(item)"
              >
                close
              </span>
              <span class="material-symbols-outlined icon" v-else>done</span>
            </div>
          </div>
        </div>
      </md-card-content>
      <md-card-content v-else>
        <Loading />
      </md-card-content>
      <md-card-actions class="text-center">
        <md-button
          native-type="submit"
          @click.native.prevent="salvaFavoritos"
          class="md-primary salvar"
        >
          Salvar
        </md-button>
      </md-card-actions>
    </md-card>
    <PrefModelosFavoritos />
    <PrefModelosAdicionais
      v-if="consultasAdicionais.length > 0"
      :consultasAdicionais="consultasAdicionais"
      :produtos="produtos"
    />
    <PrefSuperbox
      v-if="liberaTela && produtosSuperBoxCust.length > 0"
      :liberaTela="liberaTela"
      :produtos="produtos"
      :produtosSuperBoxCust="produtosSuperBoxCust"
    />
  </div>
</template>

<script>
import { userPreferenceStore } from "@/stores/userPreference";
import { produtosStore } from "@/stores/produtos";
import { chatOutboxStore } from "@/stores/chat-Outbox.js";
import { mapWritableState, mapActions } from "pinia";
import { criaCampos } from "@/helpers";
import jsonProdutos from "@/helpers/produtos.json";
import Swal from "sweetalert2";
import Loading from "@/components/Loading";
import PrefParametrosConsulta from "@/pages/MinhaConta/PrefParametrosConsulta.vue";
import PrefSuperbox from "@/pages/MinhaConta/PrefSuperbox.vue";
import PrefGerais from "@/pages/MinhaConta/PrefGerais.vue";
import PrefModelosAdicionais from "@/pages/MinhaConta/PrefModelosAdicionais.vue";
import PrefModelosFavoritos from "@/pages/MinhaConta/PrefModelosFavoritos.vue";

export default {
  name: "Preferências",

  components: {
    Loading,
    PrefParametrosConsulta,
    PrefSuperbox,
    PrefModelosAdicionais,
    PrefModelosFavoritos,
    PrefGerais,
  },

  data() {
    return {
      naoSelecionados: [],
      todosOsProdutos: {},
      liberaTela: false,
      campos: [],
      camposPF: [],
      camposPJ: [],
      camposGEO: [],
      imprimePf: [],
      imprimePj: [],
      imprimeGeo: [],
      objDesc: {
        Nome: "Descrição",
        Requerido: true,
        Mascara: "",
        Tamanho: 2,
        Valor: "",
        Visivel: false,
      },
    };
  },
  async mounted() {
    const contexto = {
      codigo_agrupador: "",
      rota: this.$route.path,
      nome: this.$route.name,
      descricao: "",
    };

    this.recebeContexto(contexto);

    await this.getProdutos();
    this.produtos.forEach((produto) => {
      if (
        produto.Classificacao !== "Superbox" &&
        produto.Classificacao !== "Superbox Customizados" &&
        !produto.Box.startsWith("BOXSUP")
      ) {
        this.todosOsProdutos[produto.Box] = produto.Nome;
      }
    });

    const preferenciasFavoritos = await this.syncPreferencias();
    // this.naoExibe = await this.syncPreferenciaConsultaAdicional();

    if (preferenciasFavoritos === undefined) {
      await this.createPreferencia("PortalOutbox_produtosFavoritos");
    } else {
      const valor = preferenciasFavoritos.Valor;
      if (valor != null) {
        this.produtosPreferidos = valor.split(";");
      }
    }

    await this.processaPreferencia("PortalOutbox_parametrosSugeridosPf", "pf");
    await this.processaPreferencia("PortalOutbox_parametrosSugeridosPj", "pj");
    await this.processaPreferencia(
      "PortalOutbox_parametrosSugeridosGeo",
      "geo"
    );

    this.campos = criaCampos.montaCampos(jsonProdutos);

    const objPF = this.campos.find((campo) => campo.Nome === "CPF");
    const objPJ = this.campos.find((campo) => campo.Nome === "CNPJ");
    const objGEO = this.campos.find((campo) => campo.Nome === "CEP");

    this.camposPF.push({ ...this.objDesc }, { ...objPF }, { ...objGEO });
    this.camposPJ.push({ ...this.objDesc }, { ...objPJ }, { ...objGEO });
    this.camposGEO.push({ ...this.objDesc }, { ...objGEO });

    for (let i in this.camposPF) {
      this.camposPF[i].Tipo = "Pessoa Física";
      this.camposPF[1].Requerido = true;
    }
    for (let i in this.camposPJ) {
      this.camposPJ[i].Tipo = "Pessoa Jurídica";
      this.camposPJ[1].Requerido = true;
    }
    for (let i in this.camposGEO) {
      this.camposGEO[i].Tipo = "Geolocalização";
      this.camposGEO[1].Requerido = true;
    }

    this.liberaTela = true;
  },

  computed: {
    consultasAdicionais() {
      const produtosFiltrados = this.produtos.filter(
        (produto) =>
          produto.Box === "BOXSUPPF001" ||
          produto.Box === "BOXSUPPF005" ||
          produto.Box === "BOXSUPPJ001" ||
          produto.Box === "BOXSUPPJ002" ||
          produto.Box === "BOXKYC001"
      );
      return produtosFiltrados.map((consulta) => {
        return {
          ...consulta,
          Valor: consulta.Box,
        };
      });
    },

    ...mapWritableState(userPreferenceStore, {
      produtosPreferidos: "produtosPreferidos",
      consultaAdicional: "consultaAdicional",
      parametrosSugeridos: "parametrosSugeridos",
    }),

    ...mapWritableState(produtosStore, {
      produtos: "produtos",
      produtosSuperBoxCust: "produtosSuperBoxCust",
    }),

    liberaSalvarPf() {
      if (
        this.camposPF[0].Valor.length > 0 ||
        this.camposPF[1].Valor.length > 0
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    // naoExibe: async function (newVal, oldVal) {
    //   await this.switchPreferenciaConsultaAdicional();
    // },
  },
  methods: {
    ...mapActions(chatOutboxStore, ["recebeContexto"]),

    ...mapActions(userPreferenceStore, [
      "syncPreferencias",
      "createPreferencia",
      "substituiPreferencia",
      // "syncPreferenciaConsultaAdicional",
      "syncPreferenciaGeral",
    ]),
    ...mapActions(produtosStore, ["getProdutos"]),

    // async switchPreferenciaConsultaAdicional() {
    //   try {
    //     await this.substituiPreferencia(
    //       "PortalOutbox_consultaAdicional",
    //       this.naoExibe.toString()
    //     );
    //   } catch (err) {}
    // },

    async processaPreferencia(parametroNome, tipo) {
      const preferencia = await this.syncPreferenciaGeral(parametroNome);

      if (preferencia === undefined) {
        await this.createPreferencia(parametroNome);
      } else {
        const valorGet = preferencia.Valor;
        if (valorGet != null) {
          if (typeof this.parametrosSugeridos[tipo] === "undefined") {
            this.parametrosSugeridos[tipo] = [];
          }
          const objetos = valorGet.split("; ").map((item) => {
            const valores = item.split(", ");
            const objeto = {};
            for (let i = 0; i < valores.length; i++) {
              if (!/^ *$/.test(valores[i])) {
                objeto[this.verificaDocumento(valores[i])] = valores[i];
              }
            }
            return objeto;
          });

          const novoObjetos = objetos.filter((objeto) => {
            return Object.keys(objeto).length !== 0;
          });

          this.parametrosSugeridos[tipo] = novoObjetos;
          this[`imprime${tipo.charAt(0).toUpperCase() + tipo.slice(1)}`] =
            this.parametrosSugeridos[tipo];
        }
      }
    },

    verificaDocumento(str) {
      const cleanedStr = str.replace(/[^\d]/g, "");
      if (cleanedStr.length === 11) {
        return "CPF";
      }

      if (cleanedStr.length === 14 && str.includes("/") && str.includes("-")) {
        return "CNPJ";
      }

      if (cleanedStr.length === 8 && str.includes("-")) {
        return "CEP";
      }

      return "Descrição";
    },
    trocaEstadoFavorito(item) {
      if (this.produtosPreferidos.includes(item)) {
        this.produtosPreferidos = this.produtosPreferidos.filter(
          (produtoPref) => item != produtoPref
        );
      } else {
        this.produtosPreferidos.push(item);
      }
    },

    adicionaParametro(campoInput) {
      //adiciona novos parametros em pf
      if (campoInput[0].Tipo == "Pessoa Física") {
        this.camposPF = campoInput;

        if (typeof this.parametrosSugeridos.pf === "undefined") {
          this.parametrosSugeridos.pf = [];
        }

        const novoObjeto = {};

        for (const campo of this.camposPF) {
          if (campo.Valor.trim() !== "") {
            novoObjeto[campo.Nome] = campo.Valor;
          }
          campo.Valor = "";
        }

        const exists = this.parametrosSugeridos.pf.some((item) => {
          const { Descrição, ...restItem } = item;
          const { Descrição: novoDescrição, ...restNovoObjeto } = novoObjeto;

          return JSON.stringify(restItem) === JSON.stringify(restNovoObjeto);
        });

        if (!exists && Object.keys(novoObjeto).length > 0) {
          this.parametrosSugeridos.pf.push(novoObjeto);
          this.alertaSucesso();
        } else {
          this.alertaErro();
        }
        this.imprimePf = this.parametrosSugeridos.pf;
      }
      const valoresPostPf = this.parametrosSugeridos.pf
        .map((obj) => Object.values(obj).join(", "))
        .join("; ");

      this.substituiPreferencia(
        "PortalOutbox_parametrosSugeridosPf",
        valoresPostPf
      );

      //adiciona novos parametros em pj
      if (campoInput[0].Tipo == "Pessoa Jurídica") {
        this.camposPJ = campoInput;

        if (typeof this.parametrosSugeridos.pj === "undefined") {
          this.parametrosSugeridos.pj = [];
        }

        const novoObjeto = {};

        for (const campo of this.camposPJ) {
          if (campo.Valor.trim() !== "") {
            novoObjeto[campo.Nome] = campo.Valor;
          }
          campo.Valor = "";
        }

        const exists = this.parametrosSugeridos.pf.some((item) => {
          const { Descrição, ...restItem } = item;
          const { Descrição: novoDescrição, ...restNovoObjeto } = novoObjeto;

          return JSON.stringify(restItem) === JSON.stringify(restNovoObjeto);
        });
        if (!exists && Object.keys(novoObjeto).length > 0) {
          this.parametrosSugeridos.pj.push(novoObjeto);
          this.alertaSucesso();
        } else {
          this.alertaErro();
        }
        this.imprimePj = this.parametrosSugeridos.pj;
      }
      const valoresPostPj = this.parametrosSugeridos.pj
        .map((obj) => Object.values(obj).join(", "))
        .join("; ");

      this.substituiPreferencia(
        "PortalOutbox_parametrosSugeridosPj",
        valoresPostPj
      );

      //adiciona novos parametros em geo
      if (campoInput[0].Tipo == "Geolocalização") {
        this.camposGEO = campoInput;

        if (typeof this.parametrosSugeridos.geo === "undefined") {
          this.parametrosSugeridos.geo = [];
        }

        const novoObjeto = {};

        for (const campo of this.camposGEO) {
          if (campo.Valor.trim() !== "") {
            novoObjeto[campo.Nome] = campo.Valor;
          }
          campo.Valor = "";
        }

        const exists = this.parametrosSugeridos.pf.some((item) => {
          const { Descrição, ...restItem } = item;
          const { Descrição: novoDescrição, ...restNovoObjeto } = novoObjeto;

          return JSON.stringify(restItem) === JSON.stringify(restNovoObjeto);
        });

        if (!exists && Object.keys(novoObjeto).length > 0) {
          this.parametrosSugeridos.geo.push(novoObjeto);
          this.alertaSucesso();
        } else {
          this.alertaErro();
        }
        this.imprimeGeo = this.parametrosSugeridos.geo;
      }
      const valoresPostGeo = this.parametrosSugeridos.geo
        .map((obj) => Object.values(obj).join(", "))
        .join("; ");

      this.substituiPreferencia(
        "PortalOutbox_parametrosSugeridosGeo",
        valoresPostGeo
      );
    },

    salvaFavoritos() {
      this.substituiPreferencia(
        "PortalOutbox_produtosFavoritos",
        this.produtosPreferidos.join(";")
      );
      Swal.fire({
        title: "Sucesso",
        text: "Produtos favoritos gravados com sucesso!",
        icon: "success",
        buttonsStyling: false,
        customClass: {
          confirmButton: "md-button md-success",
        },
        confirmButtonText: "Ok",
        showConfirmButton: true,
      });
    },

    deletarParametro(parametro, tipo) {
      if (tipo == "Pessoa Física") {
        this.parametrosSugeridos.pf = this.parametrosSugeridos.pf.filter(
          (item) => item !== parametro
        );

        if (this.parametrosSugeridos.pf.length > 0) {
          const valoresPost = this.parametrosSugeridos.pf
            .map((obj) => Object.values(obj).join(", "))
            .join("; ");

          this.substituiPreferencia(
            "PortalOutbox_parametrosSugeridosPf",
            valoresPost
          );
          this.imprimePf = this.parametrosSugeridos.pf;
        } else {
          this.substituiPreferencia("PortalOutbox_parametrosSugeridosPf", "");
          this.imprimePf = {};
        }
        this.alertaDeletar();
      }

      if (tipo == "Pessoa Jurídica") {
        this.parametrosSugeridos.pj = this.parametrosSugeridos.pj.filter(
          (item) => item !== parametro
        );

        if (this.parametrosSugeridos.pj.length > 0) {
          const valoresPost = this.parametrosSugeridos.pj
            .map((obj) => Object.values(obj).join(", "))
            .join("; ");

          this.substituiPreferencia(
            "PortalOutbox_parametrosSugeridosPj",
            valoresPost
          );
          this.imprimePj = this.parametrosSugeridos.pj;
        } else {
          this.substituiPreferencia("PortalOutbox_parametrosSugeridosPj", "");
          this.imprimePj = {};
        }
        this.alertaDeletar();
      }

      if (tipo == "Geolocalização") {
        this.parametrosSugeridos.geo = this.parametrosSugeridos.geo.filter(
          (item) => item !== parametro
        );

        if (this.parametrosSugeridos.geo.length > 0) {
          const valoresPost = this.parametrosSugeridos.geo
            .map((obj) => Object.values(obj).join(", "))
            .join("; ");

          this.substituiPreferencia(
            "PortalOutbox_parametrosSugeridosGeo",
            valoresPost
          );
          this.imprimeGeo = this.parametrosSugeridos.geo;
        } else {
          this.substituiPreferencia("PortalOutbox_parametrosSugeridosGeo", "");
          this.imprimeGeo = {};
        }
        this.alertaDeletar();
      }
    },

    alertaSucesso() {
      Swal.fire({
        title: "Sucesso",
        text: "Parâmetros favoritos gravados com sucesso!",
        icon: "success",
        buttonsStyling: false,
        customClass: {
          confirmButton: "md-button md-success",
        },
        confirmButtonText: "Ok",
        showConfirmButton: true,
      });
    },
    alertaErro() {
      Swal.fire({
        title: "Valor inválido",
        text: "Valor inválido ou já existente. Verifique os valores e tente novamente.",
        icon: "warning",
        buttonsStyling: false,
        customClass: {
          confirmButton: "md-button",
        },
        confirmButtonText: "Ok",
        showConfirmButton: true,
      });
    },
    alertaDeletarPergunta(parametro, tipo) {
      Swal.fire({
        icon: "warning",
        html: "<font size='5'>Deseja deletar o Parâmetro adicionado?</font>",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: '<i class="fas fa-check-circle"></i> Sim!',
        confirmButtonAriaLabel: "Sim!",
        cancelButtonText: '<i class="fas fa-ban"></i> Não',
        cancelButtonAriaLabel: "Não",
        confirmButtonColor: "#2196f3",
        focusConfirm: false,
        allowEnterKey: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.deletarParametro(parametro, tipo);
        }
      });
    },

    alertaDeletar() {
      Swal.fire({
        title: "Sucesso",
        text: "Paramentro deletado com sucesso.",
        icon: "success",
        buttonsStyling: false,
        customClass: {
          confirmButton: "md-button md-success",
        },
        confirmButtonText: "Ok",
        showConfirmButton: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.favoritos {
  margin-top: 2.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  &__titulo {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 3rem;
  }
  &__valores {
    display: flex;
    gap: 0.3rem;
    flex-wrap: wrap;
    font-size: 0.7rem;

    @include mediaQuery(medium) {
      width: 80%;
      font-size: 1rem;
      gap: 0.5rem;
      font-weight: 500;
    }

    &__item {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      color: white;
      padding: 5px;
      border-radius: 10px;
      background: $text-gray;
      cursor: pointer;
      transition: 0.3s ease all;
      &--selecionado {
        background: $outbox-medium-blue;
        color: white;
      }

      &:hover {
        box-shadow: 0px 5px 10px 2px white;
      }

      .icon {
        font-size: 0.8rem;
      }
    }
  }
}
.box-inputs {
  padding: 10px;
  margin: 0 10px;

  .favoritos {
    margin: 5px 0 0 0;
    width: 300px;
  }
}
.salvar {
  height: 30px;
  width: 100px;
}
</style>
